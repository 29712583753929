export const uaMessages = {
    "welcome": "Ласкаво просимо!",
    "name": "Ім'я",
    "room": "Кімната",
    "phone": "Телефон",
    "message": "Повідомлення",
    "fillForm": "Заповніть форму",
    "youCanSendMessage": "Якщо ми можемо зробити що-небудь для вашого більш комфортного проживання, будь ласка, дайте нам знати.",
    "connectWithUs": "Будемо на зв'язку: ",
    "pleaseWait": "Будь ласка зачекайте...",
    "send": "Відправити",
    "responseChannel": "Як з вами зв'язатися?",
    "requestSendError": "Не вдалося надіслати запит. Зверніться до адміністратора!",
    "requestSendSuccess": "Ваш запит надіслано! Будь ласка, чекайте відповіді на вказані вами контакти!",
    "viber": "Viber",
    "telegram": "Telegram",
    "whatsapp": "Whatsapp",
    "selectMethod": "Виберіть метод"
}