import {FormattedMessage} from 'react-intl'
import {useEffect, useState} from "react";

const InfoMessage = () => {

    const [hotelContacts, setHotelContacts] = useState({
        address: "",
        email: "",
        hotel: "",
    });

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const urlHotel = urlSearchParams.get("hotel");
        setHotelContacts(getAddressEmailByHotel(urlHotel));
    }, []);

    const getAddressEmailByHotel = (urlHotel) => {
        switch (urlHotel) {
            case "OCTC":
                return {
                    address: "Khimshiashvili str 7",
                    email: "orbicity@orbi.ge",
                    hotel: "Orbi City",
                };
            case "STA":
                return {
                    address: "Khimshiashvili str 15a",
                    email: "fosta@orbi.ge",
                    hotel: "Orbi Sea Towers",
                };
            case "STB":
                return {
                    address: "Khimshiashvili str 15b",
                    email: "fostb@orbi.ge",
                    hotel: "Orbi Sea Towers",
                };
            case "STG":
                return {
                    address: "Khimshiashvili str 15 g",
                    email: "fostg@orbi.ge",
                    hotel: "Orbi Sea Towers",
                };
            case "OBT":
                return {
                    address: "Khimshiashvili str 57",
                    email: "fob@orbi.ge",
                    hotel: "Orbi Beach Tower",
                };
            case "OPL":
                return {
                    address: "Kobaladze str 4",
                    email: "fop@orbi.ge",
                    hotel: "Orbi Plaza",
                };
            case "OR":
                return {
                    address: "Kobaladze str 2",
                    email: "for@orbi.ge",
                    hotel: "Orbi Residence",
                };
            case "OPD":
                return {
                    address: "Didveli str 32",
                    email: "reservation@orbipalace.ge",
                    hotel: "Orbi Palace Bakuriani",
                };
            case "OP":
                return {
                    address: "Didveli str 32",
                    email: "reservation@orbipalace.ge",
                    hotel: "Orbi Palace Bakuriani",
                };
            default:
                return {
                    address: "khimshiashvili str 7",
                    email: "orbicity@orbi.ge",
                    hotel: "Orbi City",
                };
        }
    }

    return (
        <>
            <div style={{display: "flex", height: 50}}>
                <img src="/img/logo_orbi_hotels.png" alt="logo" style={{width: 40, height: 40}}/>
                <p className="logo-text">{hotelContacts.hotel}</p>
            </div>
            <div style={{height: 10}}/>
            <h3 className="title"><FormattedMessage id={"welcome"}/></h3>
            <p className="text">
                <FormattedMessage id={"youCanSendMessage"}/>
            </p>

            <div className="info">
                <div className="information">
                    <img src="img/location.png" className="icon" alt=""/>
                    <p>{hotelContacts.address}</p>
                </div>
                <div className="information">
                    <img src="img/email.png" className="icon" alt=""/>
                    <p>{hotelContacts.email}</p>
                </div>
                {/*<div className="information">*/}
                {/*    <img src="img/phone.png" className="icon" alt=""/>*/}
                {/*    <p>+995 555 31 00 00</p>*/}
                {/*</div>*/}
            </div>
        </>
    )
}

export default InfoMessage;