import "./SocialButtons.css";
import { FormattedMessage } from 'react-intl'

const SocialButtons = () => {
    
    return (
        <>
            <div className="social-media">
                <p><FormattedMessage id={"connectWithUs"}/></p>
                <div className="social-icons">
                    <a href="https://www.facebook.com/orbihotels" target="_blank">
                        <i className="fab fa-facebook-f"/>
                    </a>
                    <a href="https://www.youtube.com/c/OrbiHotels" target="_blank">
                        <i className="fab fa-youtube"/>
                    </a>
                    <a href="https://www.instagram.com/orbihotels" target="_blank">
                        <i className="fab fa-instagram"/>
                    </a>
                    <a href="https://www.linkedin.com/company/orbihotels" target="_blank">
                        <i className="fab fa-linkedin-in"/>
                    </a>
                </div>
            </div>
        </>
    )
    
}

export default SocialButtons;