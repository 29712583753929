export const kaMessages = {
    "welcome": "კეთილი იყოს თქვენი მობრძანება!",
    "name": "სახელი",
    "room": "ოთახის ნომერი",
    "phone": "მობილური",
    "message": "შეტყობინება",
    "fillForm": "შეავსეთ ფორმა",
    "youCanSendMessage": "დაგვიკავშირდით ნებისმიერი პრობლემის შემთხვევაში. თითოეული სტუმრის კომფორტი ჩვენი ყველაზე დიდი პრიორიტეტია.",
    "connectWithUs": "დაგვიკავშირდით :",
    "pleaseWait": "დაელოდეთ...",
    "send": "გაგზავნა",
    "responseChannel": "როგორ დაგიკავშირდეთ?",
    "requestSendError": "თქვენი მოთხოვნა არ შესრულდა. გთხოვთ მიმართოთ ადმინისტრატორს!",
    "requestSendSuccess": "თქვენი მოთხოვნა გაიგზავნა. დაელოდეთ უკუკავშირს!",
    "viber": "Viber",
    "telegram": "Telegram",
    "whatsapp": "Whatsapp",
    "selectMethod": "აირჩიეთ მეთოდი"
}