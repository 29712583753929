export const enMessages = {
    "welcome": "Let us contact you!",
    "name": "Name",
    "room": "Room",
    "phone": "Phone",
    "message": "Message",
    "fillForm": "Fill the form",
    "youCanSendMessage": "If there is anything we can do to make your stay more comfortable, please do let us know.",
    "connectWithUs": "Connect with us :",
    "pleaseWait": "Please wait...",
    "send": "Submit",
    "responseChannel": "How we can contact your?",
    "requestSendError": "Request send failure. Please contact reception administrator!",
    "requestSendSuccess": "Your request was sent. Please wait for the answer!",
    "viber": "Viber",
    "telegram": "Telegram",
    "whatsapp": "Whatsapp",
    "selectMethod": "Select method"
}