import React, { useEffect, useRef, useState } from "react";
import "./SelectResponseChannel.css";
import { FormattedMessage } from 'react-intl'

const values = [
    {
        icon: "fas fa-phone",
        label: "phone"
    },
    {
        icon: "fab fa-viber",
        label: "viber"
    },
    {
        icon: "fab fa-telegram",
        label: "telegram"
    },
    {
        icon: "fab fa-whatsapp",
        label: "whatsapp"
    }
];

const SelectResponseChannel = ({onValueChanged}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentValue, setCurrentValue] = useState(undefined);
    let ref = useRef(null);

    const handleOpen = (event) => {
        event.preventDefault();
        setIsOpen(!isOpen);
    }

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
        }
    }

    const handleClickValue = (newValue) => {
        setCurrentValue(newValue);
        setIsOpen(false);
        onValueChanged(newValue.label);
    }

    useEffect(() => {
        setCurrentValue(values[0]);
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [])

    return (
        <>
            <div className="dropdown" ref={ref}>
                <div className="dropdown-button-container">
                    <button onClick={handleOpen} className="dropdown-button">{currentValue === undefined ?
                        <FormattedMessage id={"selectMethod"} /> :
                        <FormattedMessage id={currentValue.label} />}</button>
                    <label htmlFor=""><FormattedMessage id={"responseChannel"} /></label>
                    <span><FormattedMessage id={"responseChannel"} /></span>
                </div>
                <div id="myDropdown" className={"dropdown-content" + (isOpen ? " show " : "")}>
                    {values.map((value) =>
                        <div className="dropdown-item" key={value.label} onClick={() => handleClickValue(value)}>
                            <i className={value.icon} />
                            <p style={{ marginLeft: 10 }}><FormattedMessage id={value.label} /></p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}


export default SelectResponseChannel;
