import { createContext, useContext, useState } from 'react'

const LanguageContext = createContext("en");
const ChangeLanguageContext = createContext(undefined);

export function useLanguage() {
    return useContext(LanguageContext);
}

export function useChangeLanguage() {
    return useContext(ChangeLanguageContext);
}

export function LanguageProvider({children, language}) {
    const [currentLanguage, setCurrentLanguage] = useState(language);
    
    const changeLanguage = (newLanguage) => {
      setCurrentLanguage(newLanguage);
    }
    
    return (
        <LanguageContext.Provider value={currentLanguage}>
            <ChangeLanguageContext.Provider value={changeLanguage}>
                {children}
            </ChangeLanguageContext.Provider>
        </LanguageContext.Provider>
    )
}

