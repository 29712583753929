import './App.css';
import ContactForm from './components/ContactForm'
import SocialButtons from './components/SocialButtons'
import InfoMessage from './components/InfoMessage'
import { ruMessages } from './lang/ru/ru'
import { useChangeLanguage, useLanguage } from './contexts/LanguageProvider'
import { enMessages } from './lang/en/en'
import { IntlProvider } from 'react-intl'
import { kaMessages } from './lang/ka/ka'
import { uaMessages } from './lang/ua/ua'
import { arMessages } from './lang/ar/ar'

function App() {

    const handleSuccessSubmit = () => {
    }

    const languages = {
        "en": enMessages,
        "ru": ruMessages,
        "ka": kaMessages,
        "ua": uaMessages,
        "ar": arMessages
    }

    const currentLanguage = useLanguage();

    return (
        <>
            <IntlProvider defaultLocale={ "en" } locale={ currentLanguage } messages={ languages[currentLanguage] }>
                <div className="container">
                    <span className="big-circle" />
                    <img src="img/shape.png" className="square" alt="" />
                    <div className="form">
                        <div className="contact-info">
                            <InfoMessage />
                            <SocialButtons />
                        </div>
                        <div className="contact-form">
                            <span className="circle one" />
                            <span className="circle two" />
                            <ContactForm onSuccess={ handleSuccessSubmit } />
                        </div>
                    </div>
                </div>
            </IntlProvider>
        </>
    );
}

export default App;
