import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useChangeLanguage, useLanguage } from '../contexts/LanguageProvider'
import "./ContactForm.css"
import Select from "react-select/base";
import { currentApi } from '../constants/DefaultValues'
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import SelectResponseChannel from './SelectResponseChannel'

const ContactForm = ({ onSuccess }) => {

    const [nameFocused, setNameFocused] = useState(false);
    const [roomFocused, setRoomFocused] = useState(false);
    const [phoneFocused, setPhoneFocused] = useState(false);
    const [messageFocused, setMessageFocused] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [sendFinished, setSendFinished] = useState(false);
    const [serverError, setServerError] = useState(false);

    let phoneRef = useRef(null);

    const currentLanguage = useLanguage();
    const changeLanguage = useChangeLanguage();

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();

    useEffect(() => {
        setValue("responseChannel", "phone", { shouldValidate: false });
    }, [])

    const parseHotelType = (urlHotel) => {
        switch (urlHotel) {
            case "OCTC":
                return "OCTC"
            case "STA":
                return "STA"
            case "STB":
                return "STB"
            case "STG":
                return "STG"
            case "OBT":
                return "OBT"
            case "OPL":
                return "OPL"
            case "OR":
                return "OR"
            case "OPD":
                return "OPD"
            case "OP":
                return "OP"
            default:
                return "OCTCA";
        }
    }

    const handleSendButton = async (data) => {
        setSendFinished(false);
        setIsSubmitting(true);
        setServerError(false);

        const urlSearchParams = new URLSearchParams(window.location.search);
        const urlHotel = urlSearchParams.get("hotel");
        const hotel = parseHotelType(urlHotel);

        const dto = {
            hotel: hotel,
            name: data.name,
            room: data.room,
            phone: data.phone,
            message: data.message,
            responseChannel: data.responseChannel
        };

        try {
            const response = await fetch(currentApi + "ClientMessage", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(dto)
            });

            //console.log(response);

            if (response.status === 200) {
                setSendFinished(true);
                setTimeout(() => {
                    reset();
                    phoneRef.current.setNumber("", true);
                    setSendFinished(false);
                }, 10000);
                onSuccess();
            } else {
                setSendFinished(false);
                setServerError(true);
                setTimeout(() => {
                    setServerError(false);
                }, 10000);
            }
        } catch (e) {
            setSendFinished(false);
            setServerError(true);
            setTimeout(() => {
                setServerError(false);
            }, 10000);
        } finally {
            setIsSubmitting(false);
        }
    }

    const handleOnBlur = (event, fieldName) => {
        if (event.target.value === "") {
            switch (fieldName) {
                case "name":
                    setNameFocused(false);
                    break;
                case "room":
                    setRoomFocused(false);
                    break;
                case "phone":
                    setPhoneFocused(false);
                    break;
                case "message":
                    setMessageFocused(false);
                    break;
                default:
                    break;
            }

        }
    }

    const SubmitButton = () => {

        if (sendFinished) return <><p style={{ color: "white" }}><FormattedMessage id={"requestSendSuccess"} />
        </p></>
        if (serverError) return <><p style={{ color: "red" }}><FormattedMessage id={"requestSendError"} /></p></>

        return (
            <>
                {isSubmitting &&
                    <button className="btn">
                        <i className="fa fa-spinner fa-spin" /> <FormattedMessage id={"pleaseWait"} />
                    </button>
                }
                {(!isSubmitting) &&
                    <button type="submit" className="btn">
                        <FormattedMessage id={"send"} />
                    </button>
                }
            </>
        )
    }

    return (
        <>
            <form onSubmit={handleSubmit(handleSendButton)} autoComplete="off">
                <div style={{ display: 'flex' }}>
                    <h3 className="title"><FormattedMessage id={"fillForm"} /></h3>
                    <span style={{ flexGrow: 1 }} />
                    <select id="selectLanguage" value={currentLanguage}
                            onChange={(e) => changeLanguage(e.target.value)}>
                        <option value={"en"}>EN</option>
                        <option value={"ka"}>GE</option>
                        <option value={"ru"}>RU</option>
                        <option value={"ua"}>UA</option>
                        <option value={"ar"}>AR</option>
                    </select>
                </div>
                <div className={"input-container" + (nameFocused ? " focus " : "")}
                     onBlur={(e) => handleOnBlur(e, "name")}>
                    <input type="text"
                           name="name"
                           readOnly={sendFinished || isSubmitting}
                           className={"input "}
                           style={errors.name ? { border: "2px solid #ff0000" } : {}}
                           onFocus={() => setNameFocused(true)}
                           {...register("name", { required: true })} />
                    <label htmlFor=""><FormattedMessage id={"name"} /></label>
                    <span><FormattedMessage id={"name"} /></span>
                </div>
                <div className={"input-container" + (roomFocused ? " focus " : "")}
                     onBlur={(e) => handleOnBlur(e, "room")}>
                    <input type="text"
                           readOnly={sendFinished || isSubmitting}
                           name="room"
                           className="input"
                           style={errors.room ? { border: "2px solid #ff0000" } : {}}
                           onFocus={() => setRoomFocused(true)}
                           {...register("room", { required: true })}
                    />
                    <label htmlFor=""><FormattedMessage id={"room"} /></label>
                    <span><FormattedMessage id={"room"} /></span>
                </div>
                <div className={"input-container focus" + (phoneFocused ? " focus " : "")}
                     onBlur={(e) => handleOnBlur(e, "phone")}>
                    <IntlTelInput
                        readOnly={sendFinished || isSubmitting}
                        containerClassName="intl-tel-input"
                        inputClassName="input"
                        defaultCountry={"ge"}
                        preferredCountries={["ge","ua","ru"]}
                        telInputProps={{ style: errors.phone ? { border: "2px solid #ff0000" } : {} }}
                        style={{ width: "100%" }}
                        ref={phoneRef}
                        onPhoneNumberChange={(isValid, value, selectedCountryData, fullNumber, extension) => setValue("phone", fullNumber, { shouldValidate: false })}
                    />
                    <input type="hidden" {...register("phone", { required: true })} />
                    <label htmlFor=""><FormattedMessage id={"phone"} /></label>
                    <span><FormattedMessage id={"phone"} /> </span>
                </div>
                <div className={"input-container focus"}>
                    <SelectResponseChannel
                        onValueChanged={(newValue) => setValue("responseChannel", newValue, { shouldValidate: false })} />
                    <input type="hidden" {...register("responseChannel", { required: true })} />
                </div>
                <div className={"input-container textarea" + (messageFocused ? " focus " : "")}
                     onBlur={(e) => handleOnBlur(e, "message")}>
                    <textarea name="message"
                              id={"message"}
                              readOnly={sendFinished || isSubmitting}
                              className="input"
                              style={errors.message ? { border: "2px solid #ff0000" } : {}}
                              onFocus={() => setMessageFocused(true)}
                              {...register("message", { required: true })}
                    />
                    <label htmlFor=""><FormattedMessage id={"message"} /></label>
                    <span><FormattedMessage id={"message"} /></span>
                </div>
                <SubmitButton />
            </form>
        </>
    );
}

export default ContactForm;