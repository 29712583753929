export const arMessages = {
    "welcome": "اهلا وسهلا بكم",
    "name": "الاسم",
    "room": "رقم الغرفة",
    "phone": "رقم الاتصال: ( رمز البلد )",
    "message": "الملاحظات",
    "fillForm": "املأ الاستمارة",
    "youCanSendMessage": "و هنالك من شئ يجعل اقامتك اكثر راحة! الرجاء ابلاغنا بذلك",
    "connectWithUs": "اتصل بنا",
    "pleaseWait": "من فضلك انتظر",
    "send": "إرسال",
    "responseChannel": "كيف يمكننا الاتصال بك",
    "requestSendError": "فشل ارسال الطلب. يرجى الاتصال بموظف الاستقبال.",
    "requestSendSuccess": "فشل إرسال الطلب. من فضلك، انتظر الجواب.",
    "viber": "Viber",
    "telegram": "Telegram",
    "whatsapp": "Whatsapp",
    "selectMethod": "როგორ დაგიკავშირდეთ"
}