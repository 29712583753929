export const ruMessages = {
    "welcome": "Добро пожаловать!",
    "name": "Имя",
    "room": "Комната",
    "phone": "Телефон",
    "message": "Сообщение",
    "fillForm": "Заполните форму",
    "youCanSendMessage": "Если мы можем сделать что нибудь для вашего более комфортного проживания , пожалуйста дайте нам знать.",
    "connectWithUs": "Будем на связи :",
    "pleaseWait": "Пожалуйста подождите...",
    "send": "Отправить",
    "responseChannel": "Как с вами связаться?",
    "requestSendError": "Request send failure. Please contact reception administrator!",
    "requestSendSuccess": "Ваш запрос отправлен! Пожалуйста ожидайте ответа по указанным вами контактам!",
    "viber": "Viber",
    "telegram": "Telegram",
    "whatsapp": "Whatsapp",
    "selectMethod": "Выберите метод"
}